.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
  font-size: 17px;
}

p {
  font-size: 18px;
  color: #ccc;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  padding: 8px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: #efefef;
  font-size: 12px;
}

.footer a {
  text-decoration: none;
  color: #ffa500;
}
